import { BrowserRouter as Router, Link, Route, Switch } from "react-router-dom";
import React from "react";
import { OverviewWidget } from "./widget-routes/OverviewWidget";
import { EditWidget } from "./widget-routes/EditWidget";
import { SettingsWidget } from "./widget-routes/SettingsWidget";
import { StatisticsWidget } from "./widget-routes/StatisticsWidget";
import MSPECSClientView from "./components/MSPECSClientView.js";

export function Routes() {
  return (
    <Router>
      <div>
        <Switch>
          <Route path="/:brand/overview/:externalListingId">
            <OverviewWidget />
          </Route>
          <Route path="/:brand/edit/:externalListingId">
            <EditWidget />
          </Route>
          <Route path="/:brand/statistics/:externalListingId">
            <StatisticsWidget />
          </Route>
          <Route path="/:brand/settings/">
            <SettingsWidget />
          </Route>
          <Route exact path="/mspecs/listing/:token">
            <MSPECSClientView text={""} />
          </Route>
          <Route exact path="/">
            <Home />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

function Home() {

  const token =
    "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJUT0tFTl9UWVBFIjoiUFJPVklERVJfQ0xJRU5UX0VORFBPSU5UX0NIQUxMRU5HRSIsInBheWxvYWQiOnsiYWNjZXNzVG9rZW4iOiJNREF0TVh3d01EQXdNREF3TURnNE5Id3lPRFEuIiwic3Vic2NyaWJlcklkIjoiTURBdE1Yd3dNREF3TURBd01EZzROSHd5T0RRLiIsInVzZXJBY2NvdW50SWQiOiJNREF0TVh3d01EQXdNREF3T1RZMU1IdzAiLCJkZWFsSWQiOiJNRFl3Tm53d01EQXdNREF3TURBd00zdzFPQS4uIiwicHJvdmlkZXJTZXJ2aWNlSWQiOiJNREF0TVh3d01EQXdNREF3TURFNE5Id3lPRE0uIiwiaXNQcmV2aWV3IjpmYWxzZX0sImlhdCI6MTY0MDI0MDA2MCwiZXhwIjoxNjQxMTA0MDYwfQ.A2545jPlPw35r7ekWWUlAJCaQoU5LVncM55JLn20QayU4EDi0Vk-aFXuXXTMnJt8MHNmTy7uyHK37-7ege6hWQ";

  return (
    <div>
      <h2>Home</h2>
      <ul>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/user-details">User Details</Link>
        </li>
        <li>
          <Link to="/listing-overview">Listing Overview</Link>
        </li>
        <li>
          <Link to="/listing-edit">Listing Edit</Link>
        </li> 
        <li>
          <Link to={`/mspecs/listing/${token}`}>Client View</Link>
        </li>
      </ul>
    </div>
  );
}
import axios from 'axios';
import { config } from '../../config';

export const mapOrder = (array, order, key) => {
  array.sort(function (a, b) {
    var A = a[key], B = b[key];
    if (order.indexOf(A + "") > order.indexOf(B + "")) {
      return 1;
    } else {
      return -1;
    }
  });
  return array;
};


export const getDateWithFormat = () => {
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; //January is 0!

  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }
  return dd + '.' + mm + '.' + yyyy;
}

export const getCurrentTime = () => {
  const now = new Date();
  return now.getHours() + ":" + now.getMinutes()
}

export const getDirection = () => {
  let direction = "ltr";
  if (localStorage.getItem("direction")) {
    const localValue = localStorage.getItem("direction");
    if (localValue === "rtl" || localValue === "ltr") {
      direction = localValue;
    }
  }
  return {
    direction,
    isRtl: direction === "rtl"
  };
};

export const setDirection = localValue => {
  let direction = "ltr";
  if (localValue === "rtl" || localValue === "ltr") {
    direction = localValue;
  }
  localStorage.setItem("direction", direction);
};


export const getGeoInfo = async () => {
  return axios.get('https://ipapi.co/json/')
    .then((response) => {
      return response.data
    }).catch((error) => {
      return error;
    });
};


export const getRegionNameStr = data => {
  return data.type === 'country' ? data.name :
    (data.type === 'region' || data.type === 'geo_market') ?
      data.name + ', ' + data.country_name :
      data.name + ', ' + data.region + ', ' + data.country_name;
};

export const getCurrentAd = () => {
  const currentAd = JSON.parse(localStorage.getItem('currentAd'));

  return ({
    message: currentAd ? currentAd.message : 'We help brokers with advertising on social media',
    businessName: currentAd ? currentAd.businessName : 'Keller Williams',
    headline: currentAd ? currentAd.headline : '',
    website: currentAd ? currentAd.website : 'https://',
    description: currentAd ? currentAd.description : '',
    forSaleShow: currentAd ? currentAd.forSaleShow : false,
    underOfferShow: currentAd ? currentAd.underOfferShow : false,
    profileImageShow: currentAd ? currentAd.profileImageShow : false,
    mediaType: currentAd ? currentAd.mediaType : 'image',
    stuff: currentAd ? currentAd.stuff : null,
    soldShow: currentAd ? currentAd.soldShow : false,
  });
}

export const getUserImgSrc = src => {
  return src.startsWith('images/user/') ?
    `${config.GS_BUCKET_URL}/${src}`
    : src;
}

export const getDomain = url => {
  if (url.includes('zoopla.co.uk')) {
    return 'zoopla.co.uk';
  }
  else if (url.includes('onthemarket.com')) {
    return 'onthemarket.com';
  }
  else if (url.includes('rightmove.co.uk')) {
    return 'rightmove.co.uk';
  } else {
    throw new Error('Not valid url')
  }
}

export const isEmpty = (obj) => {
  for (let key in obj) {
    return false;
  }
  return true;
}

export const trimLink = url => {
  url = url.toLowerCase()
  url = url.replace('https://', '')
  url = url.replace('http://', '')
  url = url.replace('www.', '')
  const data = url.split('/');

  return data[0];
}

export const ensureHttpsProtocol = url => {
  if (url && url.startsWith('https://')) {
    return url
  } else if (url && url.startsWith('http://')) {
    return 'https://' + url.substr(8)
  } else if(!url){
    return 'https://'
  } else {
    return 'https://' + url
  }
}

export const stringIsAValidUrl = (s) => {
  try {
    new URL(s);
    return true;
  } catch (err) {
    return false;
  }
};

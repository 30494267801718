import React, { useState, useEffect, useCallback, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { config } from "../config.js";
import * as apiService from "../service/ApiService";
import { logger } from "../service/LoggerService";
import { ObjectContext } from "../context/ObjectContext";
import { ListingAdCampaignWizard } from "./ListingAdCampaignWizard.js";
import { ListingAdWithCampaignStatus } from "./ListingAdWithCampaignStatus.js";
import { Spinner } from "./spinner/Spinner";
import { WaitingForBBLisiting } from "./waiting-for-listing/WaitingForBBListing";

function MSPECSClientView(props) {
  const params = useParams();

  const data = jwt_decode(params.token);

  // console.log(data)

  const { state, setState } = useContext(ObjectContext);
  const [loading, setLoading] = useState(true);
  const [userToken, setUserToken] = useState(null);
  // const [savedUserToken, setSavedUserToken] = useState(null)
  const brand = "mspecs";

  const officeId = data.payload.subscriberId; // officid för Brf väva
  const customerId = data.payload.subscriberId;
  // const quedroApiUrl = "https://api1.test1.quedro.com";

  // const widgetsAppUrl = config.widgetsAppUrl
  const tokenApiUrl = config.tokenApiUrl;

  const extUserId = data.payload?.userId;
  const extListingId = data.payload?.dealId;

  const [externalUserId, setExternalUserId] = useState(extUserId);
  const [externalListingId, setExternalListingId] = useState(extListingId);

  const [aftNote, setAftNote] = useState(false);
  let savedUserToken = null
  const generateTokenUrl =
    tokenApiUrl +
    "/auth_token?" +
    "clientId=quedro-cid" +
    "&clientSecret=quedro-secret&externalUserId=" +
    externalUserId +
    "&externalOfficeId=" +
    officeId +
    "&externalCustomerId=" +
    customerId;

    const fetchToken = ()=>{
      if (tokenApiUrl && externalUserId){
        fetch(generateTokenUrl)
      .then((response) => response.json())
      .then((data) => {
        setUserToken(data.token);
      });
      }
    }

  useEffect(() => {
    fetchToken()    
  }, []);

  const fetchAsync = useCallback(async () => {
      console.log("getting Listing inside useCallback")
      logger.log("Fetching brief...");
      const data = await apiService.getListingBrief(externalListingId);
      logger.log("DONE Fetching brief...");
      logger.log(data);
      if (data.error?.status === 404) {
        setLoading(false);
        return;
      }
  
      const campaignSettingsForListingId =
        await apiService.campaignSettingsForExtendedListingId(data._id, savedUserToken);
  
      const objectCopy = { ...state };
      objectCopy.extendedListing = data;
      objectCopy.campaignSettingsForListingId =
        campaignSettingsForListingId?.campaignConfigForExtendedListing;
      setState(objectCopy);
      setLoading(false);
  }, [state, externalListingId, setState]);

  useEffect(() => {
    
    if(userToken && (userToken !== savedUserToken)) {
      console.log("Saving Token")
      savedUserToken = apiService.saveToken(userToken)
    }
    if (savedUserToken && externalListingId){
      console.log("Calling FetchAsync...,");
      fetchAsync();
    }
  }, [userToken, externalListingId]);

  if(loading){
    return (
      <Spinner />
    )
  }else{
    let listingStates = ['pending','onHold','creating','created','stopped'];
    let aftNoteStates = ['afterNotePending','afterNoteOnHold','afterNoteCreating','afterNoteCreated','afterNoteStopped'];
    
    if (state.extendedListing) {
      if (
        state.extendedListing.campaignState === "created" ||
        state.extendedListing.campaignState === "stopped" ||
        state.extendedListing.campaignState === "afterNoteCreated" ||
        state.extendedListing.campaignState === "afterNoteStopped"
      ) {
        return (
          <ListingAdWithCampaignStatus setAftNote={setAftNote} externalListingId={externalListingId}></ListingAdWithCampaignStatus>
        );
      } else {
        window.location.href=`/${brand}/edit/${externalListingId}?token=${userToken}`
      }
    } else {
      return <WaitingForBBLisiting></WaitingForBBLisiting>
    }
  }

  // return <div></div>;
}

export default MSPECSClientView;

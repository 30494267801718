export function MissingObjectError({externalIdFromToken, externalListingId}) {
    const mailToLink = 'mailto: admin@brightbee.com?' +
        'subject=I want to advertise socially!' +
        '&body=Object mnissing%0D%Cannot find  externalListingId \'' + externalListingId + '\'';
    let component = <div className="center">
        <h2>Ouups, nåt gick galet!</h2>
        <b className='red-alert-small'>Vi hittar tyvärr inte något matchande objekt i vår databas</b>
        <p>Objektets id är '{externalListingId}'</p>
        <a href={mailToLink}>Anmäl gärna felet till oss här!</a>
    </div>
    return component
}
function extractErrors(errors) {
  const ret = [];
  if (errors.response && errors.response.data) {
    const data = errors.response.data;
    const error = data.errors ? data.errors : data.error;
    const err = error.message.message ? error.message : error;
    ret.push({ code: 400, ...err });
  } else if (ret.length === 0 && errors.message) {
    ret.push({ code: 500, message: 'CONNECTION_FAILED' });
  } else {
    ret.push({ code: -1, message: 'SOMETHING_WRONG' });
    console.log('err :>> GENERAL_ERROR');
  }
  return ret;
}

export default extractErrors;

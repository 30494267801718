import {createContext} from "react";

export const defaultObjectContextState = {
    selectedPackage: null,
    selectedBudget: null,
    listingAdData: null,
    extendedListing: null,
    campaignOptions: null
}
export const ObjectContext = createContext()




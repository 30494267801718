import React, {useContext} from "react";
import {UserContext} from "../../context/UserContext";
import {logger} from "../../service/LoggerService";

export function MissingUserError() {
    const {userData} = useContext(UserContext);
    logger.log(userData)
    const externalUserId = userData.tokenInfo.externalUserId

    const mailToLink = `mailto: admin@brightbee.com?subject=I want to advertise socially!&body=Hello Brightbee!%0D%0ACan you connect my externalId '${externalUserId} to a brightbee user so I can advertise my listings easily on social media???%0D%0A%0D%0AThat would be awsome, thx!`;
    logger.log(mailToLink)
    let component = <div className="center">
        <h2>Hej och välkommen till Brightbee marketing!</h2>
        <p>Vi hjälper mäklare med att annonsera på sociala medier. Vi har funktioner för att skapa effektiva kampanjer med rätt målgrupp och spridning till rätt kostnad.</p>
        <b className='red-alert-small'>Vi hittar tyvärr inte något matchande användare i vår databas</b>
        <p>Förmodligen är ditt konto inte anslutet till något brightbeekonto...</p>
        <a href={mailToLink}>Anslut ditt konto till brightbee här!</a>
    </div>
    return component
}

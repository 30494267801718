import React, { useContext, useEffect, useState } from "react";

import * as apiService from "../service/ApiService";
import { useParams } from "react-router-dom";
import { Progress } from "reactstrap";
import { ObjectContext } from "../context/ObjectContext";
import { config } from "../config";
import { Spinner } from "./spinner/Spinner";

function getDiffDays(date2, date1) {
  const diffTime = Math.abs(date2.getTime() - date1.getTime());
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
}

export function ListingAdWithCampaignStatus(props) {
  let { externalListingId } = useParams();
  if (!externalListingId) {
    externalListingId = props.externalListingId;
  }
  let {token} = props
  const { state, setState } = useContext(ObjectContext);
  const [loading, setLoading] = useState(true);
  const [sellerEmailID, setSellerEmailID] = useState("");
  const [emailValidation, setEmailValidation] = useState("");
  const [availAdType, setAvailAdType] = useState('')

  const [chartFill, setChartFill] = useState(0);
  const [campaignInProgress, setShowProgressWidgets] = useState(true);
  const emailExpr = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );

  let availableAdTypes = []

  const validateAndSetSellerEmail = (emailVal) => {
    setSellerEmailID(emailVal);
    if (emailExpr.test(emailVal)) setEmailValidation("");
    else {
      setEmailValidation("Ogiltig e-postadress"); //Invalid email
    }
  };

  const sendVendorStatisticsEmail = async (sellerEmailID) => {
    try {
      const campaignStatsUrl = `${config.APP_WEB_URL}vendorstatistics/${state.listingAdData?.campaign._id}-${state.listingAdData?.listingAd._id}`;
      let msg, statusCode;
      setEmailValidation("Skickar mejl...");
      await apiService
        .sendVendorStatisticsEmail({
          propAddress: state.extendedListing.address.street,
          statsUrl: campaignStatsUrl,
          emailid: sellerEmailID,
          campaignID: state.listingAdData?.campaign._id,
        })
        .then((resp) => {
          msg = resp.emailResponse.message;
          setEmailValidation("");
        });

      alert(msg);
    } catch (err) {
      alert(err);
    }
  };

  const checkAvailableType = (data)=>{
    if(data){
      if(data.listingAd?.adType === "afterNote" && data.extendedListing?.listingAdId){
        return "both"
      }
      else{
        return data.listingAd?.adType
      }
    }
  }

  useEffect(() => {
    async function doStuff() {
      const data = await (state.extendedListing.isPropertySold
        ? apiService.getAfterNoteCampaignInfo(externalListingId, token)
        : apiService.getListing(externalListingId));
      const objectCopy = { ...state };
      objectCopy.listingAdData = data;
      setState(objectCopy);
      setLoading(false);
      //setSellerEmailID("Enter säljare epost")
      setAvailAdType(checkAvailableType(data))
    }
    doStuff();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      const diffDays = getDiffDays(
        new Date(state.extendedListing.createdAt),
        new Date()
      );

      const campaignDays = state.campaignSettingsForListingId.campaignDays;
      // console.log("campaignDays");
      // console.log(campaignDays);
      let fillPercent = (diffDays / campaignDays) * 100;
      if (fillPercent > 100) {
        fillPercent = 100;
      }

      setChartFill(fillPercent);

      if (fillPercent >= 100) {
        setTimeout(() => {
          setShowProgressWidgets(false);
          setChartFill(100);
        }, 1000);
      }
    }, 500);
  }, []);

  const [activeTab, setActiveTab] = useState("tab2");

  const handleTab1 = () => {
    // update the state to tab1
    setActiveTab("tab1");
  };
  const handleTab2 = () => {
    // update the state to tab2
    setActiveTab("tab2");
  };

  // if (
  //   state.listingAdData?.extendedListing?.campaignState ===
  //     "afterNoteStopped" ||
  //   state.listingAdData?.extendedListing?.campaignState === "afterNoteCreated"
  // ) {
  //   setActiveTab("tab2")
  // }

  let chFill = chartFill;

  let statsElems = null;

  if (state.listingAdData && state.listingAdData.stats) {
    statsElems = (
      <div className="row">
        <div className="col-sm-12 bbText__stats">
          <span className="bbText__stats--stat">
            <span className="bbText__stats--statNumber">
              {state.listingAdData.stats.reach}
            </span>
            <span className="bbText__stats--statText">
              Unika personer nådda
            </span>
          </span>
          <span className="bbText__stats--stat">
            <span className="bbText__stats--statNumber">
              {state.listingAdData.stats.impressions}
            </span>
            <span className="bbText__stats--statText">Totala visningar</span>
          </span>
          <span className="bbText__stats--stat">
            <span className="bbText__stats--statNumber">
              {state.listingAdData.stats.clicks}
            </span>
            <span className="bbText__stats--statText">Klick</span>
          </span>
        </div>
      </div>
    );
  }

  const listingAdDetails = <div className="container-fluid bbBG">
  <div className="row">
    <div className="bbWidget container-fluid">
      <div className="col-12 bbWidget__inner bbWidget__inner--results">
        <div className="bbWidget__results bbText">
          <span className="bbText__result bbText__result--name">
            {state.extendedListing.address.street}
          </span>
          <span className="bbText__result bbText__result--progress">
            {campaignInProgress
              ? "resultat på sociala medier"
              : "är färdigannonserad"}
          </span>
          <Progress
            className="bbWidget__progress"
            color="brightBee"
            value={chFill}
          />
          {statsElems}
          {false && state.listingAdData?.campaign?._id &&
            (state.extendedListing?.campaignState === "created" ||
              state.extendedListing?.campaignState === "stopped" 
              ) && (
              <div>
                <div className="row" style={{ marginTop: "30px" }}>
                  <div className="bbText col-12">
                    <span className="bbText__result bbText__result--name">
                      Dela med säljaren{" "}
                    </span>
                  </div>
                </div>

                <div className="row">
                  <div
                    className="bbText col-sm-3"
                    style={{ marginTop: "18px" }}
                  >
                    Säljarens e-post
                  </div>
                  <div
                    className="bbText col-sm-6"
                    style={{ marginTop: "15px" }}
                  >
                    <input
                      className="bbWidget_bbText col-sm-12"
                      type="text"
                      value={sellerEmailID}
                      onChange={(e) =>
                        validateAndSetSellerEmail(e.target.value)
                      }
                    />
                    <div
                      className="text-danger bbWidget_bbText col-sm-12"
                      style={{ marginTop: "10px" }}
                    >
                      {emailValidation}
                    </div>
                  </div>
                  <div className="bbText col-sm-3">
                    <button
                      className={
                        "bbWidget__button bbWidget__button--email"
                      }
                      disabled={emailValidation ? true : false}
                      onClick={() =>
                        sendVendorStatisticsEmail(sellerEmailID)
                      }
                    >
                      Skicka
                    </button>
                  </div>
                </div>

                {state.extendedListing.isPropertySold &&
                (state.extendedListing.campaignState ===
                  "stopped" ||
                  state.extendedListing.campaignState ===
                    "onHold") ? (
                  <div className="row" style={{ marginTop: "5px" }}>
                    <div
                      className="bbText col-sm-9"
                      style={{ marginTop: "10px" }}
                    >
                      <b>Vill du skapa en efterlap annons?!!</b>
                    </div>
                    <div className="bbText col-sm-3">
                      <button
                        className={
                          "bbWidget__button bbWidget__button--email"
                        }
                        onClick={() => {
                          props.setAftNote(true);
                        }}
                      >
                        Annonsera+
                      </button>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            )}
          <br />
          <span className="bbText__result bbText__result--progress">
            {state.extendedListing.campaignState === "stopped"
              ? "Listkampanjen har avslutats"
              : ""}
          </span>
        </div>
      </div>

      {/* <div className={'bbWidget__copy'}>
          <span className={'bbWidget__copy--bb'}>BrightBee</span>
          <span className={'bbWidget__copy--bbCopyright'}>© 2021 BrightBee Marketing AB</span>
      </div> */}
    </div>
  </div>
</div>

const afterNoteListingAdDetails = <div className="container-fluid bbBG">
<div className="row">
  <div className="bbWidget container-fluid">
    <div className="col-12 bbWidget__inner bbWidget__inner--results">
      <div className="bbWidget__results bbText">
        <span className="bbText__result bbText__result--name">
          {state.extendedListing.address.street}
        </span>
        <span className="bbText__result bbText__result--progress">
          {campaignInProgress
            ? "resultat på sociala medier"
            : "är färdigannonserad"}
        </span>
        <Progress
          className="bbWidget__progress"
          color="brightBee"
          value={chFill}
        />
        {statsElems}
        {false && state.listingAdData?.campaign?._id &&
          (state.extendedListing?.campaignState ==="afterNoteCreated" ||
            state.extendedListing?.campaignState === "afterNoteStopped") && (
            <div>
              <div className="row" style={{ marginTop: "30px" }}>
                <div className="bbText col-12">
                  <span className="bbText__result bbText__result--name">
                    Dela med säljaren{" "}
                  </span>
                </div>
              </div>

              <div className="row">
                <div
                  className="bbText col-sm-3"
                  style={{ marginTop: "18px" }}
                >
                  Säljarens e-post
                </div>
                <div
                  className="bbText col-sm-6"
                  style={{ marginTop: "15px" }}
                >
                  <input
                    className="bbWidget_bbText col-sm-12"
                    type="text"
                    value={sellerEmailID}
                    onChange={(e) =>
                      validateAndSetSellerEmail(e.target.value)
                    }
                  />
                  <div
                    className="text-danger bbWidget_bbText col-sm-12"
                    style={{ marginTop: "10px" }}
                  >
                    {emailValidation}
                  </div>
                </div>
                <div className="bbText col-sm-3">
                  <button
                    className={
                      "bbWidget__button bbWidget__button--email"
                    }
                    disabled={emailValidation ? true : false}
                    onClick={() =>
                      sendVendorStatisticsEmail(sellerEmailID)
                    }
                  >
                    Skicka
                  </button>
                </div>
              </div>

              {state.extendedListing.isPropertySold &&
              (state.extendedListing.campaignState ===
                "afterNoteStopped" ||
                state.extendedListing.campaignState ===
                  "afterNoteOnHold") ? (
                <div className="row" style={{ marginTop: "5px" }}>
                  <div
                    className="bbText col-sm-9"
                    style={{ marginTop: "10px" }}
                  >
                    <b>Vill du skapa en efterlap annons?!!</b>
                  </div>
                  <div className="bbText col-sm-3">
                    <button
                      className={
                        "bbWidget__button bbWidget__button--email"
                      }
                      onClick={() => {
                        props.setAftNote(true);
                      }}
                    >
                      Annonsera+
                    </button>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          )}
        <br />
        <span className="bbText__result bbText__result--progress">
          {state.extendedListing.campaignState ===
          "afterNoteStopped"
            ? "Listkampanjen har avslutats"
            : ""}
        </span>
      </div>
    </div>

    {/* <div className={'bbWidget__copy'}>
          <span className={'bbWidget__copy--bb'}>BrightBee</span>
          <span className={'bbWidget__copy--bbCopyright'}>© 2021 BrightBee Marketing AB</span>
      </div> */}
  </div>
</div>
</div>

const adObjects = {
  'afterNote' : afterNoteListingAdDetails,
  'listing' : listingAdDetails
}

  return (
    <>
      {/* New Design */}
      {/* {!loading ?  (
        state?.listingAdData && <StatisticsPage state={state}/>
      )
       : (
         <Spinner />
       )
      } */}

      {/* Old Design */}
      {loading ? (
        <Spinner />
      ) : (
      <div className="Tabs">
        {availAdType === "both" ? (
          <>
            <ul className="nav">
              <li
                className={activeTab === "tab1" ? "active" : ""}
                onClick={handleTab1}
              >
                Listing
              </li>
              <li
                className={activeTab === "tab2" ? "active" : ""}
                onClick={handleTab2}
              >
                AfterNote
              </li>
            </ul>
            <div className="outlet">
              {activeTab === "tab1"
                ?  adObjects.listing
                :  adObjects.afterNote }
            </div>
          </>
        ) : (
          adObjects[availAdType]
        )}
      </div>
      )}
    </>
  );
}

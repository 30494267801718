import React, {useState, useEffect, useContext, useCallback} from "react";
import {useParams} from "react-router-dom";
import {Spinner} from "../components/spinner/Spinner";
import * as apiService from "../service/ApiService";
import {MissingObjectError} from "../components/error/MissingObjectError";
import {MissingUserError} from "../components/error/MissingUserError";
import {ObjectContext} from "../context/ObjectContext";
import {UserContext} from "../context/UserContext";
import {EditingWidgetForListingAd} from "../components/edit-view/EditingWidgetForListingAd";
import {logger} from "../service/LoggerService";



export function EditWidget() {
    const token = apiService.getApiUserToken()

    let {externalListingId} = useParams();
    const { state, setState } = useContext(ObjectContext);
    const { userData } = useContext(UserContext);
    const [loading, setLoading] = useState(true);

    const fetchAsync = useCallback(async () => {
        const extendedListing = await apiService.getListingBrief(externalListingId);
        if (extendedListing.error?.status === 404) {
            setLoading(false)
            return
        }
        let objectCopy = {...state};
        objectCopy.extendedListing = extendedListing
        const listingAdId = extendedListing.isPropertySold? extendedListing.aftNoteListingAdId : extendedListing.listingAdId
        const listingAd = await apiService.getListingAd(listingAdId);
        objectCopy.listingAd = listingAd
        setState(objectCopy);
        setLoading(false)
    }, [state, externalListingId, setState])

    function closeView() {
        logger.log('posting close_brightbee_edit_widget...')
        window.top.postMessage('close_brightbee_edit_widget','*')
    }

    useEffect( () => {
        fetchAsync()
    }, []);

    if (loading) {
        return <div className="center">
            <Spinner/>
        </div>
    }

    logger.log(JSON.stringify(state, null, 3))

    if (userData.userInfo === null && userData.tokenInfo !== null) {
        return <MissingUserError></MissingUserError>
    }

    if (! state.extendedListing) {
        return <MissingObjectError></MissingObjectError>
    }

    if (state.extendedListing.campaignState !== 'created' || state.extendedListing.campaignState !== 'afterNoteCreated') {    // TODO, visa tills annonsen är ute på FB, hur veta? Kolla med William
        return <div className="bbBG">
                <div className="bbWidget bbWidget--edit">
                    <div className="bbWidget__inner bbWidget__inner--edit">
                        <EditingWidgetForListingAd></EditingWidgetForListingAd>
                    </div>
                    {/*
                    <div className={'bbWidget__copy'}>
                        <span className={'bbWidget__copy--bb'}>BrightBee</span>
                        <span className={'bbWidget__copy--bbCopyright'}>© 2021 BrightBee Marketing AB</span>
                    </div> */}
                </div>
            </div>

    } else {
        closeView();
        return <div>
            <h1>Annonsen är redan ute på sociala medier</h1>
            <p>
                <a href={`/quedro/overview/${externalListingId}?token=${token}`}>Följ upp annons här</a>
            </p>
        </div>
    }



}

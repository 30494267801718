import {config} from "../config";


export function getQueryVariable(variable) {
    const query = window.location.search.substring(1);
    const vars = query.split('&');
    for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split('=');
        if (decodeURIComponent(pair[0]) === variable) {
            return decodeURIComponent(pair[1]);
        }
    }
    console.log('Query variable %s not found', variable);
}


export function getBaseURL() {
    const apibase = config.API_BASE;
    console.log('Getting baseurl. ' + apibase)
    return apibase;
}

export const buttonClassName = 'btn btn-primary'
export const buttonWideClassName = 'btn btn-primary btn-block'
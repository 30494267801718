import {buttonClassName} from "../../utils/utils";
import {Check, Check2, CheckCircleFill} from "react-bootstrap-icons";
import React from "react";
import {config} from "../../config";

export function WaitingForBBLisiting() {

    return <div style={{height: '396px'}} className='container-fluid bbWidget__inner'>
        <div className="row">
            <div className="col-2 center">
                <img style={{ width: 30, opacity: 0.9 }} src={`${config.BASE_URL}/assets/images/facebook.png`} alt="facebook"/>
                <img style={{ width: 30, opacity: 0.9 }} src={`${config.BASE_URL}/assets/images/instagram.png`} alt="facebook"/>
            </div>
            <div className="col-8 left">
                    <div style={{ fontWeight: 'bold' }}>
                        <span>Din annons laddar...</span><br/>
                    </div>
            </div>
            <div className="col-2">
                &nbsp;
            </div>
        </div>
        <br/>
        
        <div className="row">
            <div className="col-2 center">
            </div>
            <div className="col left">
                <span>Notera att det kan ta ett par minuter innan du kan göra dina ändringar. Därefter har du en timme på dig att redigera din annons. </span><br/>
            </div>
        </div>
        <br/>
        <div className="row">
            <div className="col-2 center">
            </div>
            <div className="col left">
                <span>Vänligen stäng detta fönster och öppna upp det igen om ett par minuter.</span>
            </div>
        </div>
    </div>
}
import React, {useContext, useState} from "react";
import {ObjectContext} from "../../context/ObjectContext";
import {Spinner} from "reactstrap";
import {ChevronLeft, ChevronRight, InfoCircle} from 'react-bootstrap-icons';
export function PackageSelectionStep(props) {

    const { state, setState } = useContext(ObjectContext);
    const [packageType, setPackageType] = useState(state.extendedListing.packageType || 'listing');

    if (!state.campaignOptions) {
        return <Spinner></Spinner>
    }

    function setSelectedPackage(packageT) {
        setState({...state, selectedPackage: packageT})
        setPackageType(packageT)
    }

    function createPackageSelector(packageT, packageName, disabled) {
        return <div className={packageType === packageT ? 'bbWidget__package bbWidget__package--selected' : 'bbWidget__package' + disabled ? 'bbWidget__package disabled':''} onClick={() => disabled ? '' : setSelectedPackage(packageT) }>
                <span className={'bbWidget__package--text'}>{packageName}</span>
                <InfoCircle/>
        </div>;
    }


    return (
        <div className='container-fluid bbWidget__inner bbWidget__inner--selection'>
                    <span className="bbText--smallTitle">Välj ett paket:</span>
            <div className="row">
                    {createPackageSelector(
                        state.campaignOptions.campaignCreationOptions.packageOptions[0].packageType,
                        state.campaignOptions.campaignCreationOptions.packageOptions[0].name, false)}
                    {createPackageSelector(
                        state.campaignOptions.campaignCreationOptions.packageOptions[1].packageType,
                        state.campaignOptions.campaignCreationOptions.packageOptions[1].name, true)}
            </div>
                <div className='bbWidget__inner--nav'>
                <button type="button" className={'bbWidget__button bbWidget__button--next'}
                            onClick={() => {
                                setSelectedPackage(packageType)
                                props.nextStepButtonHandler()
                            }}>{'Gå vidare till välj budget '} <ChevronRight/>
                    </button>
                    <button type="button" className={'bbWidget__button bbWidget__button--prev'}
                            onClick={() => {
                                props.prevStepButtonHandler()
                            }}> <ChevronLeft/>{' Gå Tillbaka'}
                    </button>
                </div>
        </div>
    );
}

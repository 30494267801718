import {buttonClassName} from "../../utils/utils";
import {Check, Check2, CheckCircleFill} from "react-bootstrap-icons";
import React from "react";
import {config} from "../../config";

export function WaitingForLisiting() {

    return <div style={{height: '396px'}} className='container-fluid bbWidget__inner'>
        <div className="row">
            <div className="col-2 center">
                <img style={{ width: 30, opacity: 0.9 }} src={`${config.BASE_URL}/assets/images/facebook.png`} alt="facebook"/>
                <img style={{ width: 30, opacity: 0.9 }} src={`${config.BASE_URL}/assets/images/instagram.png`} alt="facebook"/>
            </div>
            <div className="col-8 center">
                    <div style={{ fontWeight: 'bold' }}>
                        <span>Förbered ditt objekt för sociala medier!</span><br/>
                        <span>Säkerställ att nedan information är tillagd.</span>
                    </div>
            </div>
            <div className="col-2">
                &nbsp;
            </div>
        </div>
        <br/>
        <div className="row">
            <div className="col " style={{whiteSpace:'nowrap'}}>
                <div className={'box'}>
                    <div style={{textAlign: 'left'}}>
                        <div><Check/> Adress & kommun</div>
                        <div><Check/> Pris</div>
                        <div><Check/> Antal kvm & rum</div>
                        <div><Check/> Beskrivning</div>
                        <div><Check/> Bilder</div>
                    </div>
                </div>
            </div>
        </div>
        <br/>
        <div className="row">
            <div className="col center">
                <span>Avsluta med att publicera till dina marknadsplatser <span style={{color: 'limegreen'}}><CheckCircleFill/></span></span><br/>
                <span>Det tar ca 5 min innan du kan annonsera ditt objekt HÄR!</span>
            </div>
        </div>
    </div>
}
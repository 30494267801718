import React, {useContext, useState, useEffect, useCallback} from "react";
import {ObjectContext} from "../../context/ObjectContext";
import * as apiService from "../../service/ApiService";
import {logger} from "../../service/LoggerService";
import {ChevronLeft, ChevronRight} from 'react-bootstrap-icons';
import { Spinner } from "../spinner/Spinner";

export function AftNoteBudgetSelectionStep(props) {

    const { state, setState } = useContext(ObjectContext);
    logger.log("BudgetSelectionStep, state: ", state)
    const [budgetType, setBudgetType] = useState(state.extendedListing.budgetType || 'standard');
    const [processed, setProcessed] = useState(false)

    function setSelectedBudget(budgetT) {
        setBudgetType(budgetT)
        const newState = {...state, selectedBudget: budgetT};
        setState(newState)
    }


    const fetchAsync = useCallback(async () => {
        
        const extendedListingId = state.extendedListing._id
        const campaignOptions = await apiService.getCampaignOptions(extendedListingId)
        console.log(campaignOptions)
        const objectCopy = {...state, campaignOptions}
        setState(objectCopy)
        setProcessed(true);

    }, [setState, state])


    useEffect( () => {
        fetchAsync()
    }, []);

    function createBudgetSelector(region, campaignOption) {
        return <div className={budgetType === campaignOption.budgetType ? "bbWidget__package bbWidget__package--budget bbWidget__package--selected" : "bbWidget__package bbWidget__package--budget"}  onClick={() => {
            setSelectedBudget(campaignOption.budgetType);
        }}>
                <span className="bbWidget__budgetText bbWidget__budgetText--name">{campaignOption.name}</span>
                <span className="bbWidget__budgetText bbWidget__budgetText--price">{campaignOption.budgetAmount} KR</span>
                {/*<span className="bbWidget__budgetText bbWidget__budgetText--reach">{campaignOption.reach.data.users}<span>Visningar</span></span>*/}
                {/* Område: {region[0].name}<br/> */}
                
        </div>;
    }

    function buildBudgetOptions(){
        if(processed)
        {
            const cpgnCrtOptions = state.campaignOptions.campaignCreationOptions
            return cpgnCrtOptions.aftNoteBgtOptions.map((bgtOption,key) =>
            {
                return createBudgetSelector(cpgnCrtOptions.region,bgtOption)
            }) 
        }
        else
            return <div className='col-md-12 center'><span ><Spinner/></span></div>
    }

    return <div className='container-fluid bbWidget__inner bbWidget__inner--budgetSelect'>
        <span className="bbText--smallTitle">Välj budget:</span>
        <div className="row">
            {buildBudgetOptions()}
        </div>
       
        <div className="bbWidget__inner--nav">
                <button type="button" className={"bbWidget__button bbWidget__button--next"}
                        onClick={async () => {
                            setProcessed(false);
                            setSelectedBudget(budgetType)
                            //const selectedPackage = state.selectedPackage;
                            const selectedBudget = budgetType
                            let extListingId = state.extendedListing.extListingId
                            await apiService.createAfterNoteListingAdForExtListing(extListingId, selectedBudget);
                            setProcessed(true);
                            logger.log("Updated ExtendedListing with afternote budget, " + state.extendedListing._id);
                            props.nextStepButtonHandler()
                        }}>
                    {state.extendedListing.campaignState === 'afterNoteOnHold' ? 'Publicera' : '' }
                    {state.extendedListing.campaignState === 'afterNotePending' ? 'Uppdatera' : '' }
                    {' Annons'}
                    <ChevronRight/>
                </button>
                <button type="button" className={'bbWidget__button bbWidget__button--prev'}
                            onClick={() => {
                                props.prevStepButtonHandler()
                            }}> <ChevronLeft/>{' Avbryta'}
                    </button>
        </div>
    </div>;
}

import React, {useContext} from "react";
import {ObjectContext} from "../../context/ObjectContext";
import {useParams} from "react-router-dom";
import {buttonWideClassName, getQueryVariable} from "../../utils/utils";
import {CheckCircleFill} from 'react-bootstrap-icons';
import {logger} from "../../service/LoggerService";
export function AftNoteConfirmedStep() {

    const { state } = useContext(ObjectContext);
    // const history = useHistory();

    let { externalListingId } = useParams();
    let token = getQueryVariable('token');

    let urlWithToken = `/quedro/edit/${externalListingId}?token=${token}`;

    function switchRouteToListingOverview() {
        //window.location.href = urlWithToken
        logger.log('posting open_brightbee_edit_widget..')
        window.top.postMessage('open_brightbee_edit_widget','*')
    }

    function getErrorInfo() {
        if (!state.extendedListing.errorInfo) {
            return ''
        }

        let info = state.extendedListing.errorInfo
        const fbErrorIndication = 'with message:';
        const fbErrorLocation = info.indexOf(fbErrorIndication);
        if (fbErrorLocation) {
            info = info.substring(fbErrorLocation + fbErrorIndication.length+1)
        }

        return info;
    }

    const possibleError = <div>
        <br/>
        <b>Oooups!</b>
        <p>Vi har försökt att publicera denna annons, men fick då ett fel i publiceringsflödet, se nedan. Orsakerna kan vara flera, och det kan vara lite kryptiskt, men kan vara till hjälp för dig.
            <b> OBS! Vi kommer att göra ett nytt publiceringsförsök inom kort.</b>
        </p>
        <textarea rows={4} cols={80} contentEditable={false}>{getErrorInfo()}</textarea>
    </div>

    return <div className='container-fluid bbWidget__inner bbWidget__inner--confirmed'>
        <div className="row">
            <div className="bbText">
                <span className="bbText--confirmed">Perfekt! <CheckCircleFill/></span>
                <span className="bbText--smallTitle">{state.extendedListing.address.street}<span> är nu efterapad på sociala medier!</span></span>
                <span className="bbText--absoluteBL">Annonsen publiceras inom 1 timme</span>

                {state.extendedListing.campaignState === 'afterNotePending' && state.extendedListing.errorInfo ? possibleError : ''}

            </div>
            <div className="col-md-4 bbWidget__bbPhone">
                <div className="bbWidget__bbPhone--inner" style={{backgroundImage:'url('+state.extendedListing.imageUrl+')',backgroundSize:'cover'}}></div>
                <button type="button" className="bbWidget__button bbWidget__button--edit"
                        onClick={switchRouteToListingOverview}>Redigera annons
                </button>
            </div>
        </div>
    </div>
}

import React, {useContext, useEffect, useState, useCallback} from "react";
import {ObjectContext, defaultObjectContextState} from "./context/ObjectContext";
import {defaultUserContextState, UserContext} from "./context/UserContext";
import * as apiService from "./service/ApiService";
import {Routes} from "./Routes";
import {DataViewer} from "./utils/DataViewer";
import {getApiUserToken} from "./service/ApiService";
import {logger} from "./service/LoggerService";
import './Styles/_App.scss';

export default function App() {
    logger.log("App()...")
    apiService.getAndStoreApiUserTokenFromUrl()
    const debug = window.location.href.indexOf('debug') !== -1
    const [state, setState] = useState(defaultObjectContextState)
    const [userData, setUserData] = useState(defaultUserContextState)
    return (<ObjectContext.Provider value={{state, setState}}>
            <UserContext.Provider value={{userData, setUserData}}>
                <Main/>
                {
                    debug ?
                        <DataViewer/>
                        : <></>
                }
            </UserContext.Provider>
        </ObjectContext.Provider>
    );
}


function Main() {
    const {setUserData} = useContext(UserContext);

    const fetchAsync = useCallback(async () => {
        const token = getApiUserToken()
        const meData = await apiService.me(token);
        logger.log("meData: ", meData)
        setUserData({...meData})

    }, [setUserData])

    useEffect(() => {
        fetchAsync()
    }, []);
    return Routes();
}

import React, {useContext} from "react";

import {ObjectContext} from "../context/ObjectContext";
import {UserContext} from "../context/UserContext";

export function DataViewer() {
    const {state} = useContext(ObjectContext);
    const {userData} = useContext(UserContext);
    return (<div>
        <p>
            <a className="btn btn-primary" data-toggle="collapse" href="#collapseExample" role="button"
               aria-expanded="false" aria-controls="collapseExample">
                Show debug info
            </a>
        </p>
        <div className="collapse" id="collapseExample">
            <div className="card card-body">
                <h1>DataViewer, just for debug purposes:</h1>
                <h2>Userdata context</h2>
                <pre>
                    {JSON.stringify(userData, null, 3)}
                </pre>
                <h2>State context</h2>
                <pre>
                    {JSON.stringify(state, null, 3)}
                </pre>
            </div>
        </div>
    </div>)
}
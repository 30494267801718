import React, {useCallback, useContext, useEffect, useState} from "react";
import * as apiService from "../../service/ApiService";
import {getApiUserToken} from "../../service/ApiService";
import {ObjectContext} from "../../context/ObjectContext";
import {buttonClassName} from "../../utils/utils";
import MobileAdPreview from "../../imported-kw-components/MobileAdPreview";
import AdSettings from "../../imported-kw-components/AdSettings/ad-settings";
import {useParams} from "react-router-dom";
import {ConfirmedStep} from "../campaign-creation-steps/ConfirmedStep";
import {logger} from "../../service/LoggerService";
import { X } from 'react-bootstrap-icons';
import {Input, Spinner} from "reactstrap";
import { AftNoteConfirmedStep } from "../campaign-creation-steps/AftNoteConfirmedStep";

async function reloadListingAd(state, setState) {
    let objectCopy = {...state};
    const listingAd = await apiService.getListingAd(state.listingAd._id);
    objectCopy.listingAd = listingAd
    setState(objectCopy);
}


export function EditingWidgetForListingAd() {
    const { state, setState} = useContext(ObjectContext);
    let { externalListingId } = useParams();

    const imageBaseUrl = "https://realreach.s3.eu-north-1.amazonaws.com/"

    var websitePattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator

    const [loading, setLoading] = useState(false);
    const [listingImage, setListingImage] = useState( imageBaseUrl + state.listingAd?.listingAdImages[0] );
    const [listingImageData, setListingImageData] = useState(null);
    const extraRegions = state.extendedListing.extraRegions ? state.extendedListing.extraRegions : [];
    const [additionalRegions, setAdditionalRegions] = useState([...extraRegions]);

    const [description, setDescription] = useState('');
    const [headline, setHeadline] = useState('');
    const [message, setMessage] = useState('');
    const [website, setWebsite] = useState('');
    const [businessName, setBusinessName] = useState('');
    const [finishedWithEdititng, setFinishedWithEdititng] = useState(false);
    const [saveResponse, setSaveResponse] = useState(null)
    const [validationResponse, setValidationResponse] = useState(null)
    const [aftNoteImage, setAftNoteImage] = useState(null)
    const [aftNoteLoading, setAftNoteLoading] = useState(false)
    const [fieldsValid, setFieldsValid] = useState(state.extendedListing?.isValid)
    const [showReqMsg, setShowReqMsg] = useState(false)

    async function onSelectFiles(e) {
        const file = e.target.files[0];
        if (!file) {
            setListingImage(null)
        } else if (file.name.toLowerCase().match(/\.(jpg|jpeg|png|gif)$/)) {
            setListingImage(URL.createObjectURL(e.target.files[0]))
            setListingImageData({ mediaType: "image", listingFile: file })
        }
        if (file && state.listingAd?.adType === 'afterNote'){
            setAftNoteLoading(true)
            let formdataWithImage = new FormData()
            formdataWithImage.append('listingAdImages', file);
            let reqData = {
                existingListingId : state.extendedListing._id,
                agentName : state.extendedListing.agentInfo?.name,
                agentTitle : state.extendedListing.agentInfo?.title,
                agentPhotoUrl : state.extendedListing.agentInfo?.photoUrl,
                adType : state.listingAd?.adType,
                country : state.extendedListing.address?.country,
                listingAdImages : state.listingAd.listingAdImages,
                soldShow : true,
                helpBuyShow: false,
                profileImageShow: false,
                underOfferShow: false,
                forSaleShow: false,
                mediaType : 'image',
            }
            formdataWithImage.append('params', JSON.stringify(reqData))
            let imageResponse = await apiService.uploadandfetchImage(formdataWithImage);
            setAftNoteImage(imageResponse.image[0]?.key)
            // if(imageResponse.image[0]?.key) {
            //     setTimeout(()=>{
            //         setAftNoteLoading(false)
            //     }, 3000)
            // }
        }
    };

    function setupControlsFromCurrentExtendedListingState() {
        setDescription((!state.listingAd?.description || state.listingAd.description === 'undefined') ? '' : state.listingAd.description );
        setHeadline((!state.listingAd?.headline || state.listingAd.headline==='undefined') ? '' : state.listingAd.headline)
        setMessage((!state.listingAd?.message || state.listingAd.message === 'undefined') ? '' : state.listingAd.message);
        setWebsite(state.listingAd.website)
        setBusinessName(state.listingAd.businessName)
        setListingImage(imageBaseUrl + state.listingAd.listingAdImages[0])
    }

    const fetchAsync = useCallback(async () => {
        setDescription((!state.listingAd?.description || state.listingAd.description === 'undefined') ? '' : state.listingAd.description );
        setHeadline((!state.listingAd?.headline || state.listingAd.headline ==='undefined') ? '' : state.listingAd.headline)
        setMessage((!state.listingAd?.message || state.listingAd.message === 'undefined') ? '' : state.listingAd.message);
        setWebsite(state.listingAd.website)
        setBusinessName(state.listingAd.businessName)
    }, [state])

    useEffect(() => {
        fetchAsync();
        console.log("State : ", state&&state)
    }, []);

    function isDirty() {
        return description !== state.listingAd.description
            || headline !== state.listingAd.headline
            || message !== state.listingAd.message
            || businessName !== state.listingAd.businessName
            || website !== state.listingAd.website
            || listingImageData !== null
    }

    function closeView() {
        logger.log('posting close_brightbee_edit_widget...')
        window.top.postMessage('close_brightbee_edit_widget','*')
    }

    const checkValid = (returnValue = false)=>{
        if (message.trim() && headline.trim() && website.trim() && description.trim()){
            setFieldsValid(true)
            if (returnValue) return true
        }else{
            setFieldsValid(false)
            if (returnValue) return false
        }
    }

    // to stop the loading process of aftetnoteimage
    const stopLoading = () =>{
        setAftNoteLoading(false)
    }

    async function save() {
        logger.log("save")
        logger.log("additionalRegions")
        logger.log(additionalRegions)
        setFinishedWithEdititng(true)
        console.log("Inside save")
        // let adType
        // if(state){
        //     adType = (state.extendedListing?.campaignState === "afterNotePending" || state.extendedListing?.campaignState === "afterNoteOnHold") ? "afterNote" : "listing"
        // }

        if (isDirty() && checkValid(true)) {
            setShowReqMsg(false)
            setLoading(true)
            let requiredData = {
                headline, description, message, website, businessName, adType : state.listingAd?.adType
            }
            if (aftNoteImage){
                requiredData = {...requiredData, aftNoteImage}
            }
           let responseFromSaveApi = await apiService.saveListingAd(state.listingAd._id, requiredData);
            
            if (responseFromSaveApi){
                setSaveResponse(responseFromSaveApi)
            }

            if (listingImageData && !aftNoteImage) {
                let formdataWithImage = new FormData()
                formdataWithImage.append('listingAdImages', listingImageData.listingFile);
                await apiService.saveListingAdImage(
                    state.listingAd._id,
                    formdataWithImage);
            }
            await reloadListingAd(state, setState);
            setLoading(false)

            // let dataToSet = {
            //     extraRegions: additionalRegions,
            // }
    
            // if ( state.listingAd?.adType === "listing"){
            //     // setting campaignState as onHold due data insufficient for campaign.
            //     if (!validationResponse.allValid){
            //         dataToSet = {...dataToSet, campaignState : 'onHold'}
            //     }    
            //     else{
            //         dataToSet = {...dataToSet, campaignState : 'pending'}
            //     }
            // }else{
            //     if (!validationResponse.allValid){
            //         dataToSet = {...dataToSet, campaignState : 'afterNoteOnHold'}
            //     }    
            //     else{
            //         dataToSet = {...dataToSet, campaignState : 'afterNotePending'}
            //     }
            // }
    
            // await apiService.saveExtendedListing(state.extendedListing._id, dataToSet);
        }
        if(!fieldsValid){
            setShowReqMsg(true)
        }
        
        closeView();
    }

    function cancel() {
        logger.log("cancel")
        if (isDirty()) {
            setupControlsFromCurrentExtendedListingState();
        } else {
            let token = getApiUserToken()
            let urlWithToken = `/quedro/overview/${externalListingId}?token=${token}`;
            window.location.href = urlWithToken
        }
        closeView();
    }


    // if (finishedWithEdititng) {
    //     if(state.extendedListing.isPropertySold && state.extendedListing.aftNoteListingAdId)
    //         return <AftNoteConfirmedStep></AftNoteConfirmedStep>
    //     return <ConfirmedStep></ConfirmedStep>
    // }

    let adInfoRows =
    <div>
        <div className='row'>
                <div className="form-group">
                    <label className="label">
                        Meddelande {!message.trim() && <span className="text-danger"> * </span>}</label>
                    <textarea resize="none" className="form-control" type='text' value={message} size="40" onChange={e => {setMessage(e.target.value); checkValid()}}/>
                    {!message.trim() && <span className="text-danger">Skriv in en meddelandetext</span>}
                </div>
        </div>
        <div className='row'>
                <div className="form-group">
                    <label className="label">
                        Webbsajt {!websitePattern.test(website.trim()) && <span className="text-danger"> * </span>}</label>
                    <input className="form-control" type='text' value={website} size="40" onChange={e => {setWebsite(e.target.value); checkValid(); }}/>
                    {!websitePattern.test(website.trim()) && <span className="help-text text-danger">Ange en URL/hemsideadress till bostaden</span>}
                </div>
        </div>

        <div className='row'>
                <div className="form-group">
                <label className="label">
                    Rubrik {!headline.trim() && <span className="text-danger"> * </span>}</label>
                <input className="form-control" type='text' value={headline} size="40" onChange={e => { setHeadline(e.target.value); checkValid() }}/>
                {!headline.trim() && <span className="help-text text-danger">Ange en rubrik för bostaden</span>}
            </div>
        </div>

        <div className='row'>
                <div className="form-group">
                    <label className="label">
                        Beskrivning {!description.trim() && <span className="text-danger"> * </span>}</label>
                    <input className="form-control" type='text' value={description} size="40" onChange={e => {setDescription(e.target.value); checkValid()}}/>
                    {!description.trim() && <span className="help-text text-danger">Ange en beskrivning</span>}
                </div>
        </div>

        <div className='row'>
                <div  className="col mt-3">
                    <button
                        className="btnType2"
                        type="button"
                        onClick={() => document.getElementById("selectFile").click()}
                    >Byt bild</button>
                    {aftNoteLoading && <span className="ml-4 "><Spinner /> Bilden bearbetas... </span>}
                </div>

                <Input
                    type="file"
                    id="selectFile"
                    style={{ display: 'none' }}
                    onChange={onSelectFiles}
                />
        </div>

    </div>


    let mediaType;


    async function removeAd() {
        if(state.extendedListing.isPropertySold && state.extendedListing.aftNoteListingAdId)
            await apiService.forceAfterNoteListingToOnHold(state.extendedListing._id)
        else
            await apiService.forceExtendedListingToOnHold(state.extendedListing._id)
            
        closeView()
    }

    const savedMessage = <div style={{textAlign:"center",width:"100%"}}>
        <span>{loading ? <Spinner /> : ""}</span>
        {showReqMsg && <span  style={{fontWeight:"bold",color:"red"}}>Alla fält är obligatoriska.</span>}
        {(saveResponse) ? 
        <span style={{fontWeight:"bold",fontFamily:"inherit"}}>
            {saveResponse.error ? <span style={{color:"red"}}> {saveResponse.error.message}</span> : <span style={{color:"green"}}>Dina ändringar är sparade  och du kan stänga rutan.</span>}
        </span>
        : ""}
        </div>

    const cancelSaveButtons = <div className='bbWidget__edit--buttons'>
        {/* <button className='bbWidget__button bbWidget__button--cancel' onClick={() => cancel()}>Avbryt</button> */}
        {/* <a href="#" onClick={() => removeAd()} className="bbText--link">Ta bort annons</a> */}
        <button className='bbWidget__button  bbWidget__button--save' onClick={() => save()} disabled={!fieldsValid}>Spara ändringar</button>
        {/* <X onClick={() => cancel()}/> */}
    </div>

    const errorInfo = <div className="w-100 p-3">
        <textarea disabled="true" className="form-control w-100 text-danger" rows={"3"}>
            {state?.extendedListing?.errorInfo}
        </textarea>       
    </div>


    return <div>
        <span className="bbText bbText--editTitle">Redigera annons</span>
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-7'>
                    {adInfoRows}
                </div>
                <div className='col-5'>
                    <MobileAdPreview
                        message={message}
                        businessName={businessName}
                        headline={headline}
                        website ={website}
                        description={description}
                        soldShow={false}
                        underOfferShow={false}
                        forSaleShow={false}
                        profileImageShow={false}
                        mediaType={mediaType}
                        stuff={aftNoteImage ? imageBaseUrl+aftNoteImage : listingImage}
                        afterNoteImageLoaded = {aftNoteImage ? true : false}
                        stopLoading = {stopLoading}
                    />
                </div>
            </div>
            {/* <div className="row">
                <AdSettings
                    additionalRegions={additionalRegions}
                    setAdditionalRegions={setAdditionalRegions}
                    />
            </div> */}

            <div className='row saveButtonDiv'>
                {/* {loading ?
                    <div style={{marginTop: '80px'}} className="center"><Spinner></Spinner></div>
                    : ''
                } */}
                {cancelSaveButtons}
                <br></br>
                {savedMessage}
            </div>
            {(state?.extendedListing?.campaignState === "afterNoteOnHold" || state?.extendedListing?.campaignState === "onHold") && 
                <div className="row">
                    {errorInfo}
                </div>
            }
        </div>
    </div>
}

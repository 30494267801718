import React, {useContext} from "react";
import {ObjectContext} from "../../context/ObjectContext";
import {buttonClassName} from "../../utils/utils";

export function ListingNotAdvertisedStep(props) {
    const {nextStepButtonHandler} = props

    const { state } = useContext(ObjectContext);

    let buttonText = 'Annonsera +';
    if (state.extendedListing.campaignState === 'pending') {
        buttonText = 'Granska / Ändra'
    }

    return <div className='container-fluid bbWidget__inner bbWidget__inner--notAdvertised'>
        <div className={'row'}>
            <div className={'col-12 bbText'}>
                    <span className={'bbText--title'}>{state.extendedListing.address.street}</span>
                    <span className={'bbText--description'}>
                        {state.extendedListing.campaignState === 'onHold' ? ' finns inte på sociala medier!' : ''}
                        {state.extendedListing.campaignState === 'pending' ? ' är på väg att publiceras på sociala medier, men du hinner granska och ändra här.' : ''}
                        {state.extendedListing.campaignState === 'creating' ? ' är på väg ut på sociala medier.' : ''}
                        {state.extendedListing.campaignState === 'created' ? ' finns på sociala medier.' : ''}
                    </span>
            </div>
        </div>
        <div className="bbWidget__inner--nav">
            <button type="button" className={buttonClassName +' bbWidget__button'} onClick={nextStepButtonHandler}>{buttonText}</button>
        </div>
    </div>
}

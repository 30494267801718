window.bbLogEnabled = window.location.href.indexOf('debug') !== -1

class LoggerService {
    logImpl(args) {
        if (args.length && args.length === 1) {
            const item = args[0]
            console.log('Log: ', item)
        } else {
            console.log('Log: ', args)
        }
    }

    log(args) {
        if (window.bbLogEnabled) {
            this.logImpl(args)
        }
    }

    debug(...args) {
        if (window.bbLogEnabled) {
            this.logImpl(args)
        }
    }
}
export const logger = new LoggerService() // not using angula dependency injection atm, rather using it as logger.debug...
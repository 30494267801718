import React from 'react';
import { toast } from 'react-toastify';
import Autosuggest from 'react-autosuggest';
import { Row, Col, Input } from 'reactstrap';
import { Map, GoogleApiWrapper, Marker, Circle } from 'google-maps-react';

import * as api from '../utils/api';
import history from '../helpers/history';
import extractErrors from '../utils/error';
import { getRegionNameStr } from '../helpers/Utils';
import { X } from 'react-bootstrap-icons';

export class AdSettings extends React.Component {
  constructor(props) {
    super(props);
      this.state = {
        region: '',
        regionsArray: [...props.additionalRegions],
        geolocation: [],
      }
  }

  componentDidMount() {
  }

  geolocations = newValue => {
    api.fbGeoLoaction({ location: newValue.value })
      .then(result => {
        this.setState({ geolocation: result.data });
      })
      .catch(err => {
        let errors = extractErrors(err) || [];
        toast.error(errors[0].message);
      });
  };

  onChangeAutoSearch = name => (event, { newValue }) => {
    this.setState({ [name]: newValue });
  }

  handleRemoveSocial = (idx) => {
    let someArray = this.state.regionsArray;
    someArray.splice(idx, 1);
    this.setState({ regionsArray: someArray }, () => {
      this.props.setAdditionalRegions(this.state.regionsArray)
    });
  }

  handleInputVlaueChange = (e, idx) => {
    let nextSocialData = this.state.regionsArray.slice();
    nextSocialData[idx] = e.target.value;
    this.setState({ regionsArray: nextSocialData });
  }

  onSuggestionSelected = (event, { suggestion, suggestionValue, index, method }) => {
    let someArray = this.state.regionsArray;
    someArray.push({ ...suggestion })
    this.setState({ region:'', regionsArray: someArray }, () => {
      this.props.setAdditionalRegions(this.state.regionsArray)
    });
  }

  onSuggestionsClearRequested = () => { }

  render() {
    const {
      geolocation, region
    } = this.state;

    const inputProps = {
      placeholder: "Ex: Kungsholmen",
      value: region,
      onChange: this.onChangeAutoSearch('region'),
      className: 'form-control'
    };

    return (
      <>
                <span className="bbText bbText--areaTitle">
                  Lägg till område
                </span>
                    <div className="form-group bbWidget__areaInput">
                    <label className="label">Plats, Ort, kommun eller län</label>
                    <Autosuggest
                      className="form-control"
                      suggestions={geolocation}
                      onSuggestionsFetchRequested={this.geolocations}
                      onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                      getSuggestionValue={item => {
                        this.setState({ selectedRegion: item })
                        return getRegionNameStr(item)
                      }}
                      renderSuggestion={item => {
                        return (
                            <h6><b>{getRegionNameStr(item)}</b></h6>
                        )
                      }}

                               onSuggestionSelected={this.onSuggestionSelected}
                      inputProps={inputProps}
                    />
                    </div>
                    <div className="bbWidget__selectedAreas">
                    {this.state.regionsArray.map((Social, idx) => (
                        <div key={idx} className="bbWidget__selectedAreas--area">
                            <Input
                              disabled={true}
                              className="selectedresults"
                              type="text"
                              placeholder={`Add New # ${idx + 1}`}
                              value={getRegionNameStr(Social)}
                              onChange={e => this.handleInputVlaueChange(e, idx)}
                            />
                            <div className="bbWidget__selectedAreas--crosssighns crosssighns">
                              <X onClick={() => this.handleRemoveSocial(idx)}/>
                            </div>
                        </div>
                      ))}
                    </div>
      </>
    );
  }
}

export default AdSettings;
